'use strict';

import store from 'client/store';

import member from './member.js'
import sessionLib from './session.js'

import filter from "lodash/filter";
import find from "lodash/find";
import forEach from "lodash/forEach";
import includes from "lodash/includes";
import map from "lodash/map";
import merge from "lodash/merge";

import Vue from 'vue';

export default {
  getSet: function(session, categoryId, exerciseTypeId) {
    console.log('get panel',session, categoryId, exerciseTypeId)
    let set = 1

    const rotationType = sessionLib.getSessionRotationType(session.id)
    const sessionCategory = find(session.categories, i => i.categoryId === categoryId)

    switch (rotationType) {
      case 'mixed': {
        const sessionCategoryExercise = find(sessionCategory.exercises, i => i.exerciseTypeId === exerciseTypeId)
        set = sessionCategoryExercise.set
        break
      }
      default:
        set = sessionCategory.set
    }

    return set
  },

  getPanel: function(session, categoryId, exerciseTypeId) {
    const set = this.getSet(session, categoryId, exerciseTypeId)
    const rotationType = sessionLib.getSessionRotationType(session.id)
    const sessionPanels = filter(store.state.panels.items, i => i.sessionId === session.id)

    return find(sessionPanels,
        i => i.set === set && (rotationType !== 'rotation' || i.exerciseTypeId === exerciseTypeId))
  },

  panelName: function(panel, type = 'full') {
    const rotationType = sessionLib.getSessionRotationType(panel.sessionId)

    let name = Vue.i18n.t('panel') + ' ';
    if (rotationType === 'rotation') {
      name += (panel.index + 1);
    } else {
      name += panel.set;
    }

    if (type === 'full' && panel.exerciseTypeId) {
      name += ' ' + Vue.i18n.t('exercise.type.' + panel.exerciseTypeId);
    }

    return name;
  },

  calculatePasses: function(panel, settings) {
    let passes = settings.passes

    if (settings.rotationType !== 'mixed' && panel.activeExerciseTypeId !== null) {
      passes = filter(passes, (pass) => pass.exerciseTypeId === panel.activeExerciseTypeId );
    }

    return passes;
  },

  calculatePositions: function(panel, panelType = null) {
    if (! panelType) {
      panelType = store.state.eventDiscipline.panelTypes.find(pt => pt.id === panel.panelTypeId)
    }

    let position = 0;
    let positions = [];
    forEach(panelType.groups, group => {
      let key = group.key;
      let offset = 0;
      if (group.main) {
        key = group.main.key;
        offset = group.main.offset;
      }

      for (let i = 0; i < group.size; i++) {
        const member = find(panel.members, item => {
          return includes(item.positions, position);
        });

        const index = offset + i + 1
        const figCode = group.figCode ? group.figCode : group.key.substring(0, 1).toUpperCase()

        positions.push({
          position: position,
          key: key,
          figCode,
          name: Vue.i18n.t('score.' + key) + (group.size > 1 ? ' ' + index : ''),
          group,
          index,
          member,
          valueTypes: group.valueTypes,
        });
        position++;
      }
    });
    return positions;
  },

  getPanelConfiguration: function(exerciseType, format, options = {}) {
    const formatExerciseType = find(format.exercises, function(item) {
      return item.exerciseTypeId === exerciseType.id;
    });

    const discipline = store.state.eventDiscipline.discipline;
    const configuration = {};

    merge(configuration,
      discipline.config,
      exerciseType.config,
      format.config,
      formatExerciseType.config,
      options);

    return configuration;
  },

  getCalculationType: function(panelType, exerciseConfig) {
    const paramType = exerciseConfig.calculationType;
    return find(panelType.calculationTypes, function(calculationType) {
      return calculationType.name === paramType;
    });
  },

  getGroupJudgePositions: function(groupKey, panelType) {
    let positions = [];
    let offset = 0;
    forEach(panelType.groups, function (group) {
      if (includes(group.valueTypes, groupKey)) {
        for (let i = 0; i < group.size; i++) {
          positions.push(offset + i);
        }
      }
      offset += group.size;
    });
    return positions;
  },

  _scoreFieldWidth: function(maximum, precision) {
    let size = 0
    if (precision) {
      size = precision + 0.5
    }
    size += Math.floor(maximum).toString().length

    console.log(maximum, precision, size)

    if (size <= 3.5) {
      return 'u-50px'
    }
    else if (size <= '5.5') {
      return 'u-60px'
    }
    else {
      return 'u-80px'
    }
  },

  /**
   * Get a list of score fields for printed report.
   * For each field list label + query parameters to select value from scores.
   *
   * @param panelType
   * @param config
   * @returns {*[]}
   */
  getPanelFields: function(panelType, config, level) {
    console.log('get panel fields', panelType.id, config, level)
    const calculationType = this.getCalculationType(panelType, config)
    let fields = []

    forEach(calculationType.scoreGroups, scoreGroup => {
      const positions = this.getGroupJudgePositions(scoreGroup.key, panelType)

      forEach(scoreGroup.elements, element => {
        if (element.print >= level) {
          forEach(positions, (position, p_index) => {
            fields.push({
              label: element.key.substring(0,1).toUpperCase() + (p_index+1),
              width: this._scoreFieldWidth(element.valueType.max, element.valueType.precision),
              precision: element.valueType.precision,
              properties: {
                leg: 0,
                group: scoreGroup.key,
                element: element.key,
                position,
                index: 0,
              }
            })
          })
        }

        if (element.elementTotal.enabled && element.elementTotal.print >= level) {
          fields.push({
            label: element.key.substring(0,1).toUpperCase(),
            width: this._scoreFieldWidth(element.elementTotal.valueType.max, element.elementTotal.valueType.precision),
            precision: element.elementTotal.valueType.precision,
            properties: {
              leg: 0,
              group: scoreGroup.key,
              element: element.key,
              position: -1,
              index: 0,
            }
          })
        }
      })

      if (scoreGroup.judgeTotal.enabled && scoreGroup.judgeTotal.print >= level) {
        forEach(positions, (position, p_index) => {
          fields.push({
            label: scoreGroup.key.substring(0,1).toUpperCase() + (p_index+1),
            width: this._scoreFieldWidth(scoreGroup.judgeTotal.valueType.max, scoreGroup.judgeTotal.valueType.precision),
            precision: scoreGroup.judgeTotal.valueType.precision,
            properties: {
              leg: 0,
              group: scoreGroup.key,
              element: 'total',
              position,
              index: -1,
            }
          })
        })
      }

      // add element totals and group total
      if (scoreGroup.total.enabled && scoreGroup.total.print >= level) {
        const label = scoreGroup.shortName ? scoreGroup.shortName : scoreGroup.key.substring(0,1).toUpperCase()
        fields.push({
          label,
          width: this._scoreFieldWidth(scoreGroup.total.valueType.max, scoreGroup.total.valueType.precision),
          precision: scoreGroup.total.valueType.precision,
          properties: {
            leg: 0,
            group: scoreGroup.key,
            element: 'total',
            position: -1,
            index: -1,
          }
        })
      }
    })

    console.log(fields)

    return fields
  },

  calculateCsvFields: function(exerciseTypeId, config, panelType, pass) {
    const calculationType = this.getCalculationType(panelType, config);
    let fields = [];
    const valueBase = pass + '.';

    forEach(calculationType.scoreGroups, scoreGroup => {
      const positions = this.getGroupJudgePositions(scoreGroup.key, panelType);

      // add values entered by the judges and judge total (related to judge position)
      forEach(positions, (position, p_index) => {
        forEach(scoreGroup.elements, element => {
          const label = Vue.i18n.t('score.' + element.key) + ' ';

          for (let c = 0; c < element.count; c++) {
            const countLabel = element.count > 1 ? ' ' + (c + 1) : '';
            fields.push({
              label: label + (element.valueType.enterDeduction ? ' (ded)' : '') + ' ' + (p_index + 1) + countLabel,
              value: valueBase + scoreGroup.key.replace('.', '_') +
                '.' + element.key.replace('.', '_') + '.' + position + '.' + c,
            });
          }
        });
        if (scoreGroup.judgeTotal.enabled) {
          fields.push({
            label: Vue.i18n.t('score.' + scoreGroup.key) + ' ' + (p_index + 1),
            value: valueBase + scoreGroup.key.replace('.', '_') + '.total.' + position + '.-1',
          });
        }
      });

      // add element totals and group total
      forEach(scoreGroup.elements, element => {
        const label = Vue.i18n.t('score.' + element.key) + ' ';

        if (element.elementTotal.enabled) {
          for (let c = 0; c < element.count; c++) {
            const countLabel = element.count > 1 ? ' ' + (c + 1) : '';
            fields.push({
              label: label + (element.valueType.enterDeduction ? ' (ded)' : '') + ' ' + countLabel,
              value: valueBase + scoreGroup.key.replace('.', '_') +
                '.' + element.key.replace('.', '_') + '.-1' + '.' + c,
            });
          }
        }
      });
      if (scoreGroup.total.enabled) {
        fields.push({
          label: Vue.i18n.t('score.' + scoreGroup.key) + ' total',
          value: valueBase + scoreGroup.key.replace('.', '_') + '.total.-1.-1',
        });
      }
    });

    return fields;
  },

  exportPanel: function(panel) {
    const panelType = find(store.state.eventDiscipline.panelTypes, item => {
      return item.id === panel.panelTypeId;
    });
    const positions = filter(this.calculatePositions(panel, panelType), item => {
      return item.member !== undefined;
    });

    const positionData = map(positions, position => {
      const judge = position.member ? find(store.state.members.items, item => {
        return item.id === position.member.judgeId;
      }) : null;
      const club = position.member ? find(store.state.clubs.items, item => {
        return item.id === position.member.clubId;
      }) : null;

      return {
        id: judge.id,
        position: position.name,
        type: position.group.key,
        figCode: position.figCode,
        judgeId: judge ? judge.accessId : null,
        judge: judge ? member.getName(judge) : null,
        judgeLastName: judge ? judge.lastName : null,
        judgeFirstName: judge ? judge.firstname : null,
        club: club ? club.name : null,
        clubId: club ? club.accessId : null,
      };
    });

    return {
      set: panel.set,
      panel: this.panelName(panel),
      positions: positionData,
    };
  },
};
