import Vue from 'vue';
import config from 'client/config';
import socket from 'client/socket';

const filter = require("lodash/filter");

export default function(store) {
  const state = {
    eventDisciplineId: undefined,
    items: []
  };

  const actions = {
    "rankings.get": function(ctx, opts) {
      if (ctx.state.eventDisciplineId) {
        socket.emit('leave', 'ranking:' + ctx.state.eventDisciplineId);
      }
      store.commit("rankings.setEventDiscipline", opts.eventDisciplineId);
      Vue.http.get(config.root + '/eventDisciplines/' + opts.eventDisciplineId + '/rankings').then((result) => {
        socket.emit('join', 'ranking:' + opts.eventDisciplineId);
        store.commit('rankings.setItems', result.data);
        if (opts.cb) {
          opts.cb()
        }
      })
    },
  };

  const mutations = {
    "rankings.setEventDiscipline": function(state, eventDisciplineId) {
      Vue.set(state, 'eventDisciplineId', eventDisciplineId);
    },
    "rankings.setItems": function(state, items) {
      Vue.set(state, 'items', items);
    },
    "ranking.setItem": function(state, ranking) {
      let items = filter(state.items, function(item) {
        return item.id !== ranking.id;
      });
      items.push(ranking);
      Vue.set(state, 'items', items);
    },
    "ranking.remove": function(state, id) {
      state.items = filter(state.items, function(item) {
        return item.id !== id;
      });
    },
  };

  function listener() {
    /*socket.on('connect', function () {
      if (state.eventDisciplineId) {
        store.dispatch('rankings.get', { eventDisciplineId: state.eventDisciplineId });
      }
    });*/
    socket.on('ranking.update', function(result) {
      store.commit('ranking.setItem', result.data);
    });
    socket.on('ranking.delete', function(result) {
      store.commit('ranking.remove', result.data);
    });
    socket.emit('join', 'ranking.delete');
  }

  store.registerModule('rankings', {
    state: state,
    mutations: mutations,
    actions: actions
  });

  listener();
}
