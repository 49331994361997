<template>
  <section class="c-tab__panel c-main u-margin-top-medium" v-show="isActive" :id="id" role="tabPanel">
    <slot></slot>
  </section>
</template>

<script>
  export default {
    name: "sce-tab",
    props: {
      id: {
        required: true,
      },
      label: {
        required: true,
      },
    },
    data: function() {
      return {
        isActive: false,
      };
    },
    computed: {
    },
  }
</script>

<style scoped>

</style>
