import Vue from 'vue';
import socket from 'client/socket';
import config from 'client/config';
import filter from "lodash/filter";

export default function(store) {
  const state = {
    eventDisciplineId: undefined,
    sessionId: undefined,
    blockId: undefined,
    items: [],
    loaded: false
  };

  const actions = {
    "blockParticipations.get": function(ctx, opts) {
      leaveRooms();

      return new Promise((resolve, reject) => {
        store.commit("blockParticipations.setEventDiscipline", opts.eventDisciplineId);
        Vue.http.get(config.root + '/eventDisciplines/' + opts.eventDisciplineId + '/blockParticipations')
          .then((result) => {
          store.commit('blockParticipations.setItems', result.data);
          socket.emit('join', 'blockParticipation:' + opts.eventDisciplineId);

          resolve(result);
        }, err => reject(err));
      });
    },
    "blockParticipations.getSession": function(ctx, opts) {
      leaveRooms();

      return new Promise((resolve, reject) => {
        store.commit("blockParticipations.setSession", opts.sessionId);
        Vue.http.get(config.root + '/sessions/' + opts.sessionId + '/blockParticipations').then((result) => {
          store.commit('blockParticipations.setItems', result.data);
          socket.emit('join', 'blockParticipation:' + opts.sessionId);

          resolve(result);
        }, err => reject(err));
      });
    },
    "blockParticipations.getBlock": function(ctx, opts) {
      leaveRooms();

      return new Promise((resolve, reject) => {
        store.commit("blockParticipations.setBlock", opts.blockId);
        Vue.http.get(config.root + '/blocks/' + opts.blockId + '/participations').then((result) => {
          store.commit('blockParticipations.setItems', result.data);
          socket.emit('join', 'blockParticipation:' + opts.blockId);

          resolve(result);
        }, err => reject(err));
      });
    }
  };

  const mutations = {
    "blockParticipations.setEventDiscipline": function(state, eventDisciplineId) {
      Vue.set(state, "eventDisciplineId", eventDisciplineId);
    },
    "blockParticipations.setSession": function(state, sesionId) {
      Vue.set(state, "sesionId", sesionId);
    },
    "blockParticipations.setBlock": function(state, blockId) {
      Vue.set(state, "blockId", blockId);
    },
    "blockParticipations.setItems": function(state, data) {
      Vue.set(state, 'items', data);
      Vue.set(state, 'loaded', true);
    },
    "blockParticipation.update": function(state, data) {
      const blockParticipations = filter(state.items, function(item) {
        return item.id !== data.id;
      });
      blockParticipations.push(data);
      Vue.set(state, 'items', blockParticipations);
    },
    "blockParticipation.remove": function(state, id) {
      const blockParticipations = filter(state.items, function(item) {
        return item.id !== id;
      });
      Vue.set(state, 'items', blockParticipations);
    }
  };

  function leaveRooms() {
    if (state.eventDisciplineId) {
      socket.emit('leave', 'exercise:' + state.eventDisciplineId);
      store.commit("blockParticipations.setEventDiscipline", undefined);
    }
    if (state.sessionId) {
      socket.emit('leave', 'exercise:' + state.sessionId);
      store.commit("blockParticipations.setSession", undefined);
    }
    if (state.blockId) {
      socket.emit('leave', 'exercise:' + state.blockId);
      store.commit("blockParticipations.setBlock", undefined);
    }
  }

  function listener() {
    /*socket.on('connect', function () {
      if (state.eventDisciplineId) {
        store.dispatch('blockParticipations.get', {eventDisciplineId: state.eventDisciplineId})
      }
      if (state.blockId) {
        store.dispatch('blockParticipations.getBlock', {blockId: state.blockId})
      }
    });*/

    socket.on('blockParticipation.update', function(result) {
      store.commit('blockParticipation.update', result.data)
    })

    socket.on('blockParticipation.delete', function(result) {
      store.commit('blockParticipation.remove', result.data)
    })
    socket.emit('join', 'blockParticipation.delete')
  }

  store.registerModule('blockParticipations', {
    state: state,
    mutations: mutations,
    actions: actions
  });

  listener();
}
