import Vue from 'vue';
import config from 'client/config';
import socket from 'client/socket';

const filter = require("lodash/filter");

export default function(store) {
  const state = {
    items: [],
    current: null,
    countries: []
  };

  let src;
  const actions = {
    "events.getAll": function() {
      socket.emit('leave', 'events');

      return new Promise((resolve, reject) => {
        Vue.http.get(config.root + '/events/list').then((result) => {
          store.commit('events.setItems', result.data);
          socket.emit('join', 'events');
          Vue.http.get(config.root + '/countries', {filter: {order: 'name'}}).then((result) => {
            store.commit('events.setCountries', result.data);
            resolve();
          });
        }, err => reject(err));
      });
    },
    "events.get": function(ctx, userId) {
      socket.emit('leave', 'event:' + userId);

      return new Promise((resolve, reject) => {
        Vue.http.get(config.root + '/events/list', {params: {owner_id: userId}}).then(function(result) {
          store.commit('events.setItems', result.data);
          socket.emit('join', 'event:' + userId);
          Vue.http.get(config.root + '/countries', {filter: {order: 'name'}}).then((result) => {
            store.commit('events.setCountries', result.data);
            resolve();
          });
        }, err => reject(err));
      });
    },
    "events.getById": function(ctx, opts) {
      return new Promise((resolve, reject) => {
        Vue.http.get(config.root + '/events/' + opts.eventId).then(result => {
          store.commit('events.setCurrent', result.data);
          if (!ctx.state.countries.length) {
            Vue.http.get(config.root + '/countries', {filter: {order: 'name'}}).then((result) => {
              store.commit('events.setCountries', result.data);
            });
          }
          resolve(result);
        }, err => reject(err));
      });
    },
    "events.off": function() {
      socket.off('event.update');
      src.close()
    },
    "event.add": function(state, opts) {
      return new Promise((resolve, reject) => {
        Vue.http.post(config.root + '/seUsers/' + opts.userId + '/events', opts.event).then(
          result => {
            store.commit('events.update', result.data);
            resolve(result.data);
          },
          err => reject(err),
        );
      });
    },
    "event.insert": function(state, opts) {
      return new Promise((resolve) => {
        store.commit('events.update', opts.event);
        resolve();
      });
    },
  };

  const mutations = {
    "events.setItems": function(state, items) {
      Vue.set(state, 'items', items)
    },
    "events.setCountries": function(state, data) {
      Vue.set(state, 'countries', data);
    },
    "events.setCurrent": function(state, item) {
      Vue.set(state, 'current', item)
    },
    "events.create": function(state, item) {
      console.log('events, create');
      state.items.push(item);
    },
    "events.remove": function(state, id) {
      console.log('store remove event', id, state.items.length);
      const items = filter(state.items, function(item) {
        return item.id !== id;
      });
      console.log('store remove event', items.length);
      Vue.set(state, 'items', items);
    },
    "events.update": function(state, event) {
      const index = state.items.findIndex(i => i.id === event.id);
      let events = state.items;
      if (index >= 0) {
        events.splice(index, 1, event);
      }
      else {
        events.push(event);
      }

      Vue.set(state, 'items', events);
    },
  };

  function listener() {
    socket.on('event.update', function(result) {
      if (result.type === "create") {
        store.commit('events.create', result.data);
      }
      if (result.type === "update") {
        store.commit('events.update', result.data);
        if (state.current && result.data.id === state.current.id) {
          store.commit('events.setCurrent', result.data)
        }
      }
    });
    socket.on('event.delete', function(result){
      store.commit('events.remove', result.data);
    });
    socket.emit('join', 'event.delete');
  }

  store.registerModule('events', {
    state: state,
    mutations: mutations,
    actions: actions
  });
  listener();
}
