<template>
  <div class="c-tabs">
    <div class="c-tabs__tab-bar" role="tablist">
      <button v-for="tab in value" role="tab" :key="tab.id" v-on:click.prevent="selectTab(tab.id, $event)"
              class="c-button c-button--large c-button--ghost c-tabs__tab-link"
              :class="{ 'is-active': isActive(tab)}">
          <span class="c-tabs__tab-link-title">{{tab.label}}</span>
      </button>
    </div>
    <div class="c-tab__panel c-main u-margin-top-medium">
      <slot />
    </div>
  </div>
</template>

<script>
  import cloneDeep from 'lodash/cloneDeep';
  import find from 'lodash/find';

  export default {
    name: "sce-tabs",
    props: {
      value: {
        type: Array,
        required: true,
      },
    },
    data: function() {
      return  {
        activeTabId: '',
      }
    },
    watch: {
      value: function() {
        this.activateTab();
      },
    },
    mounted: function() {
      this.activateTab();
    },
    methods: {
      activateTab: function() {
        const activeTab = this.getActiveTab();
        const activeTabId = activeTab ? activeTab.id : this.value[0].id;
        this.$nextTick(() => {
          this.$children.forEach(tab => {
            tab.isActive = (tab.id === activeTabId);
          });
        });
      },
      isActive: function(tab) {
        return tab.isActive;
      },
      getActiveTab: function(tabs = null) {
        if (! tabs) {
          tabs = this.value;
        }
        return find(tabs, t => t.isActive);
      },
      selectTab: function(tabId) {
        const tabs = cloneDeep(this.value);
        const activeTab = this.getActiveTab(tabs);
        if (activeTab) {
          activeTab.isActive = false;
        }
        const selectedTab = find(tabs, t => t.id === tabId);
        if (! selectedTab) return;

        selectedTab.isActive = true;
        this.$emit('input', tabs);

        this.$children.forEach(tab => {
          tab.isActive = (tab.id === tabId);
        });

        this.$emit('changed', {tab: selectedTab});
      },
    },
  };
</script>

<style scoped>

</style>
