import Vue from 'vue';
import config from 'client/config';
import socket from 'client/socket';

const filter = require("lodash/filter");

export default function(store) {
  let state = {
    eventDisciplineId: undefined,
    sessionId: undefined,
    blockId: undefined,
    exerciseTypeId: undefined,
    panelId: undefined,
    items: []
  };
  const actions = {
    "exercises.get": function(ctx, opts) {
      leaveRooms();

      return new Promise((resolve, reject) => {
        store.commit("exercises.eventDiscipline", opts.eventDisciplineId);
        Vue.http.get(config.root + '/eventDisciplines/' + opts.eventDisciplineId + '/exercises').then((result) => {
          store.commit('exercises.setItems', result.data);
          socket.emit('join', 'exercise:' + opts.eventDisciplineId);
          resolve(result);
        }, err => reject(err));
      });
    },
    "exercises.getSession": function(ctx, opts) {
      leaveRooms();

      return new Promise((resolve, reject) => {
        store.commit("exercises.session", opts.sessionId);
        Vue.http.get(config.root + '/sessions/'+ opts.sessionId +'/exercises').then((result) => {
          store.commit('exercises.setItems', result.data.exercises);
          socket.emit('join', 'exercise:' + opts.sessionId);
          resolve(result.data.exercises);
        }, err => reject(err));
      });
    },
    "exercises.getBlock": function(ctx, opts) {
      leaveRooms();

      return new Promise((resolve, reject) => {
        store.commit("exercises.setBlock", opts.blockId);
        store.commit("exercises.setPanel", opts.panelId);
        Vue.http.post(config.root + '/blocks/' + opts.blockId + '/exercises', {
          panelId: opts.panelId
        }).then((result) => {
          store.commit('exercises.setItems', result.data.exercises);
          socket.emit('join', 'exercise:' + opts.blockId);
          resolve(result);
        }, err => reject(err));
      });
    },
    "exercise.setStatus": function(ctx, opts) {
      return new Promise((resolve, reject) => {
        Vue.http.patch(config.root + '/exercises/' + opts.exerciseId, { status: opts.status }).then(result => {
          resolve(result);
        }, err => reject(err));
      });
    }

  };

  const mutations = {
    "exercises.eventDiscipline": function(state, eventDisciplineId) {
      Vue.set(state, "eventDisciplineId", eventDisciplineId);
    },
    "exercises.session": function(state, sessionId) {
      Vue.set(state, "eventDisciplineId", sessionId);
    },
    "exercises.setBlock": function(state, blockId) {
      Vue.set(state, "blockId", blockId);
    },
    "exercises.setPanel": function(state, panelId) {
      Vue.set(state, "panelId", panelId);
    },
    "exercises.setItems": function(state, data) {
      Vue.set(state, 'items', data);
    },
    "exercises.update": function(state, data) {
      let exercises = filter(state.items, function(item) {
        return item.id !== data.id;
      });
      exercises.push(data);
      Vue.set(state, 'items', exercises);
    },
    "exercise.remove": function(state, id) {
      let exercises = filter(state.items, function(item) {
        return item.id !== id;
      });
      Vue.set(state, 'items', exercises);
    }
  };

  function leaveRooms() {
    if (state.eventDisciplineId) {
      socket.emit('leave', 'exercise:' + state.eventDisciplineId);
      store.commit("exercises.eventDiscipline", undefined);
    }
    if (state.sessionId) {
      socket.emit('leave', 'exercise:' + state.sessionId);
      store.commit("exercises.session", undefined);
    }
    if (state.blockId) {
      socket.emit('leave', 'exercise:' + state.blockId);
      store.commit("exercises.setBlock", undefined);
    }
  }

  function listener() {
    socket.on('connect', function () {
      if (state.eventDisciplineId) {
        store.dispatch('exercises.get', {eventDisciplineId: state.eventDisciplineId});
      }
      if (state.sessionId) {
        store.dispatch('exercises.getSession', {sessionId: state.sessionId});
      }
      if (state.blockId) {
        store.dispatch('exercises.getBlock', {blockId: state.blockId, panelId: state.panelId});
      }
    });
    socket.on('exercise.update', result => {
      if (result.type === 'update') {
        store.commit('exercises.update', result.data);
        return;
      }
      if (result.type === 'create') {
        store.commit('exercises.update', result.data);
      }
    });
    socket.on('exercise.delete', result => {
      store.commit('exercise.remove', result.data);
    });
    socket.emit('join', 'exercise.delete');
  }

  store.registerModule('exercises', {
    state: state,
    mutations: mutations,
    actions: actions
  });

  listener();
}

