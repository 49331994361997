import config from 'client/config';

import Vue from'vue';

export default function (store) {
  const state = {
    data: [],
  };

  const actions = {
    'clubSchedule.get': function (ctx, eventId) {
      return new Promise((resolve, reject) => {
        Vue.http.get(config.root +'/events/' + eventId + '/clubSchedule').then((result) => {
          store.commit('clubSchedule.set', result.data);
          resolve()
        }, err => reject(err));
      })
    },
  };

  const mutations = {
    'clubSchedule.set': function (state, data) {
      Vue.set(state, 'data', data);
    },
  };

  store.registerModule('clubSchedule', {
    state: state,
    mutations: mutations,
    actions: actions,
  });
}
