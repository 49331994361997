
import panelHandler from 'client/lib/panelHandler.js';
import store from 'client/store';

import find from "lodash/find";

export default {
  /** get combined exercise configuration based on inherited configurations */
  configuration: function(exercise) {
    console.log('calculate exercise config')
    const participation = find(store.state.participations.items, item => {
      return item.id === exercise.participationId ;
    });
    const category = find(store.state.categories.items, item => item.id === participation.categoryId)
    const categoryRound = find(category.rounds, item => item.roundIndex === exercise.roundIndex)

    const format = find(store.state.eventDiscipline.formats, item => item.id === categoryRound.formatId)
    const exerciseType = find(store.state.eventDiscipline.exerciseTypes, item => {
      return item.id === exercise.exerciseTypeId;
    });

    return panelHandler.getPanelConfiguration(exerciseType, format, categoryRound.config);
  },

  getExercise: function(participationId, roundIndex, exerciseTypeId) {
    return find(store.state.exercises.items, item => item.participationId === participationId
          && item.roundIndex === roundIndex && item.exerciseTypeId === exerciseTypeId
    )
  }
};

