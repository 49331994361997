<template>
  <div></div>
</template>

<script>
  export default {
    name: "sceInputBase",
    props: {
      value: {
        required: false,
      },
      label: String,
      disabled: {
        type: Boolean,
        default: false,
      },
      config: {
        type: Object,
        default: () => ({}),
      },
    },
    data: function() {
      return {
        inputName: '',
      }
    },
    created: function() {
      this.inputName = this.$attrs.name + '-Field';
    },
    methods: {
      updateInput: function(input = null) {
        const value = input ? input : this.$refs.input.value
        this.$emit('input', value)
      },
      getLabel: function() {
        let str = this.$t(this.label);
        if (this.config.required) {
          str += ' *';
        }
        return str;
      }
    },
  }
</script>

<style scoped>

</style>
