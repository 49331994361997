import Vue from 'vue';
import config from 'client/config';
import socket from 'client/socket';

const filter = require("lodash/filter");

export default function(store) {
  const state = {
    eventId: null,
    items: [],
  };

  const actions = {
    "eventDisciplines.get": (ctx, opts) => {
      return new Promise((resolve, reject) => {
        if (ctx.state.eventId) {
          socket.emit('leave', 'eventDiscipline:' + ctx.state.eventId);
        }

        Vue.http.get(config.root + '/events/' + opts.eventId + '/eventDisciplines').then((result) => {
          store.commit('eventDisciplines.setItems', result.data);
          store.commit('eventDisciplines.setEventId', opts.eventId);
          socket.emit('join', 'eventDiscipline:' + opts.eventId);
          resolve(result);
        }, err => reject(err));
      });
    },
    "eventDiscipline.add": function(ctx, opts) {
      return new Promise((resolve, reject) => {
        Vue.http.post(config.root + '/events/' + ctx.state.eventId + '/eventDisciplines', opts).then(
          result => {
            store.commit('eventDisciplines.update', result.data);
            resolve(result.data);
          },
          err => reject(err),
        );
      });
    },
  };

  const mutations = {
    "eventDisciplines.setEventId": function(state, eventId) {
      Vue.set(state, 'eventId', eventId);
    },
    "eventDisciplines.setItems": function(state, items) {
      Vue.set(state, 'items', items);
    },
    "eventDisciplines.remove": function(state, id) {
      const items = filter(state.items, function(item) {
        return item.id !== id;
      });
      Vue.set(state, 'items', items);
    },
    "eventDisciplines.update": function(state, item) {
      const index = state.items.findIndex(i => i.id === item.id);
      let items = state.items;
      if (index >= 0) {
        items.splice(index, 1, item);
      }
      else {
        items.push(item);
      }

      Vue.set(state, 'items', items);
    }
  };

  function listener() {
    socket.on('eventDiscipline.update', (result) => {
      console.log("update ed", result);
      store.commit('eventDisciplines.update', result.data);
    });
    socket.on('eventDiscipline.delete', function(result) {
      store.commit('eventDisciplines.remove', result.data);
    });
    socket.emit('join', 'eventDiscipline.delete');
  }

  store.registerModule('eventDisciplines', {
    state: state,
    mutations: mutations,
    actions: actions
  });
  listener();
}
