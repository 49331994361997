import Vue from 'vue';
import config from 'client/config';
import socket from "client/socket";

const filter = require("lodash/filter");

// TODO: add listener for updates
export default function(store) {
  const state = {
    items: [],
    eventDisciplineId: undefined,
  };

  const actions = {
    "categories.get": function(ctx, opts) {
      if (ctx.state.eventDisciplineId) {
        socket.emit('leave', 'category:' + ctx.state.eventDisciplineId);
      }

      return new Promise((resolve, reject) => {
        Vue.http.get(config.root + '/eventDisciplines/' + opts.eventDisciplineId + '/categories').then((result) => {
          store.commit('categories.setItems', result.data);
          store.commit('categories.eventDisciplineId', opts.eventDisciplineId);
          socket.emit('join', 'category:' + opts.eventDisciplineId);
          resolve(result);
        }, error => { reject(error); });
      });
    },
    "category.save": function(ctx, data) {
      return new Promise((resolve, reject) => {
        Vue.http.patch(config.root + '/categories/' + data.id, data).then(result => {
          store.commit('categories.update', result.data);
          resolve(result);
        }, error => { reject(error); });
      });
    },
    "category.add": function(ctx, opts) {
      opts.data.index = ctx.state.items.length;
      return new Promise((resolve, reject) => {
        Vue.http.post(config.root + '/eventDisciplines/' + ctx.state.eventDisciplineId + '/categories', opts.data)
          .then(result => {
          store.commit('categories.update', result.data)
          resolve(result)
        }, error => reject(error))
      });
    },
    "category.remove": function(ctx, category) {
      store.commit('categories.remove', category.id)
      return Vue.http.delete(config.root + '/categories/' + category.id)
    }
  };

  const mutations = {
    "categories.eventDisciplineId": function(state, eventDisciplineId) {
      Vue.set(state, 'eventDisciplineId', eventDisciplineId)
    },
    "categories.setItems": function(state, items) {
      Vue.set(state, 'items', items)
    },
    "categories.remove": function(state, id) {
      state.items = filter(state.items, i => i.id !== id)
    },
    "categories.update": function(state, category) {
      const items = filter(state.items, i => i.id !== category.id)
      items.push(category)
      Vue.set(state, 'items', items)
    },
  }

  function listener() {
    /*socket.on('connect', function () {
      if (state.eventDisciplineId) {
        store.dispatch('categories.get', {eventDisciplineId: state.eventDisciplineId});
      }
    });*/
    socket.on('category.update', function(result) {
      store.commit('categories.update', result.data);
    });
    socket.on('category.delete', function(result) {
      store.commit('categories.remove', result.data);
    });
    socket.emit('join', 'category.delete');
  }

  store.registerModule('categories', {
    state: state,
    mutations: mutations,
    actions: actions,
  });

  listener();
}
