import Vue from 'vue';
import socket from "client/socket";
import config from "client/config";

const filter = require("lodash/filter");

export default function (store) {
  let state = {
    items: [],
    eventId: null,
    displayId: null,
  };

  const actions = {
    "displays.load": function(ctx, opts) {
      if (! opts.eventId) {
        return null;
      }

      leaveRooms();
      store.commit('displays.setEventId', opts.eventId);

      return new Promise((resolve, reject) => {
        Vue.http.get(config.root + '/events/' + opts.eventId + '/displays').then(result => {
          store.commit('displays.setItems', result.data);
          socket.emit('join', 'display:' + opts.eventId);

          resolve(result);
        }, err => reject(err));
      });
    },
    "displays.loadById": function(ctx, opts) {
      if (! opts.displayId) {
        return null;
      }

      leaveRooms();
      store.commit('displays.setDisplayId', opts.displayId);

      return new Promise((resolve, reject) => {
        Vue.http.get(config.root + '/displays/' + opts.displayId).then(function (result) {
          store.commit('displays.setItems', [result.data]);
          socket.emit('join', 'display:' + opts.displayId);

          resolve(result);
        }, err => reject(err));
      });
    },
    "display.update": function(ctx, display) {
      return new Promise((resolve, reject) => {
        Vue.http.patch(config.root + '/displays/' + display.id, display).then(result => {
          store.commit("display.update", result.data);
          resolve(result.data);
        }, err => reject(err));
      });
    },
    "display.add": function(ctx, opts) {
      return new Promise((resolve, reject) => {
        Vue.http.post(config.root + '/events/' + ctx.state.eventId + '/displays', opts.display).then(result => {
          resolve(result.data);
        }, err => reject(err))
      });
    },
    "display.remove": function(ctx, opts) {
      return new Promise((resolve, reject) => {
        Vue.http.delete(config.root + '/displays/' + opts.displayId).then(result => {
          store.commit("display.remove", opts.displayId);
          resolve(result);
        }, err => reject(err));
      });
    },
    "display.setTimer": function(ctx, opts) {
      return new Promise((resolve, reject) => Vue.http.patch(config.root + '/displays/' + opts.displayId, {
        timer: opts.timer
      }).then(result => {
        store.commit("display.update", result.data);
        resolve(result.data);
      }, err => reject(err)));
    },
  };

  function leaveRooms() {
    if (state.eventId) {
      socket.emit('leave', 'display:' + state.eventId);
      store.commit("displays.setEventId", undefined);
    }
    if (state.displayId) {
      socket.emit('leave', 'display:' + state.displayId);
      store.commit("displays.setDisplayId", undefined);
    }
  }

  const mutations = {
    "displays.setItems": function(state, items) {
      Vue.set(state, 'items', items)
    },
    "displays.setEventId": function(state, eventId) {
      Vue.set(state, 'eventId', eventId);
    },
    "displays.setDisplayId": function(state, displayId) {
      Vue.set(state, 'displayId', displayId);
    },
    "display.remove": function(state, id) {
      state.items = filter(state.items, function(item) {
        return item.id !== id;
      });
    },
    "display.add": function(state, display) {
      state.items.push(display);
    },
    "display.update": function(state, display) {
      const index = state.items.findIndex(i => i.id === display.id);
      let displays = state.items;
      if (index >= 0) {
        displays.splice(index, 1, display);
      }
      else {
        displays.push(display);
      }

      Vue.set(state, 'items', displays);
    }
  };

  function listener() {
    /*socket.on('connect', function () {
      if (state.eventId) {
        store.dispatch('displays.load', {eventId: state.eventId});
      } else if (state.displayId) {
        store.dispatch('displays.loadById', {displayId: state.displayId});
      }
    });*/
    socket.on('display.update', function(result) {
      if (result.type === 'update') {
        store.commit('display.update', result.data);
        return;
      }
      if (result.type === 'create') {
        store.commit('display.add', result.data);
        return;
      }
    });
    socket.on('display.delete', result => {
      store.commit('display.remove', result.data);
    });
    socket.emit('join', 'display.delete');
  }

  store.registerModule('displays', {
    state: state,
    mutations: mutations,
    actions: actions,
  });

  listener();
}
