import Vue from 'vue';
import config from 'client/config';
import socket from 'client/socket';

const filter = require("lodash/filter");
const find = require("lodash/find");

import sessionLib from 'client/lib/session'

import {floor}  from 'mathjs';

export default function(store) {
  const state = {
    items: [],
    configurations: [],
    context: undefined,
  };

  const actions = {
    "panels.getByEvent": (ctx, opts) => {
      if (ctx.state.context) {
        socket.emit('leave', 'panel:' + ctx.state.context.id);
      }

      return new Promise((resolve, reject) => {
        Vue.http.get(config.root + '/events/' + opts.eventId + '/loadPanels').then((result) => {
          store.commit('panels.setItems', result.data.panels);
          store.commit('panels.setConfigurations', result.data.configurations);
          socket.emit('join', 'panel:' + opts.eventId);
          store.commit('panels.setContext', {type: 'event', id: opts.eventId});
          resolve(result);
        }, err => reject(err));
      });
    },
    "panels.getBySession": (ctx, opts) => {
      if (ctx.state.context) {
        socket.emit('leave', 'panel:' + ctx.state.context.id);
      }

      return new Promise((resolve, reject) => {
        Vue.http.get(config.root + '/sessions/' + opts.sessionId + '/loadPanels').then((result) => {
          store.commit('panels.setItems', result.data.panels);
          store.commit('panels.setConfigurations', result.data.configurations);
          socket.emit('join', 'panel:' + opts.sessionId);
          store.commit('panels.setContext', {type: 'session', id: opts.sessionId});
          resolve(result);
        }, err => reject(err));
      });
    },
    "panel.member.save": function (ctx, opts) {
      return new Promise((resolve, reject) => {
        Vue.http.put(config.root + '/panels/' + opts.panelId + '/panelMembers/' + opts.data.id, opts.data)
          .then(result => {
          store.commit("panel.update", result.data);
          resolve(result);
        }, err => reject(err));
      });
    },
    "panel.save": function(ctx, opts) {
      return new Promise((resolve, reject) => {
        Vue.http.patch(config.root + '/panels/' + opts.panelId, opts.data).then(result => {
          store.commit('panel.update', result.data);
          resolve(result.data);
        }, err => reject(err));
      });
    },
    "panel.member.add": function (ctx, opts) {
      return new Promise((resolve, reject) => {
        Vue.http.post(config.root + '/panels/' + opts.panelId + '/panelMembers', opts.data)
          .then(result => {
          store.commit("panel.update", result.data);
          resolve(result);
        }, err => reject(err));
      });
    },
    "panel.member.clear": function(ctx, opts) {
      return Vue.http.delete(config.root + '/panels/' + opts.panelId + '/panelMembers/' + opts.panelMemberId);
    },
    "panel.setActiveRotation": function(ctx, opts) {
      console.log('set active rotation', opts);
      const data = {
        activeRotation: opts.activeRotation,
        activeSet: opts.activeSet,
        activeExerciseTypeId: opts.activeExerciseTypeId,
        status: 'idle-block',
        exerciseId: null,
        previousExerciseId: null,
        activePass: 1,
      };
      return new Promise((resolve, reject) => {
        Vue.http.patch(config.root + '/panels/' + opts.panelId, data).then((result) => {
          store.commit("panel.update", result.data);
          console.log('rotation activated');
          resolve(result);
        }, err => reject(err));
      });
    },
    "panel.unsetActiveRotation": function(ctx, opts) {
      const panel = find(ctx.state.items, item => {
        return item.id === opts.panelId;
      });

      const data = {
        activeRotation: null,
        activeExerciseTypeId: null,
        status: 'idle',
        exerciseId: null,
        // previousExerciseId: null,
        activePass: 1,
        previousRotation: panel.activeRotation,
      };
      return new Promise((resolve, reject) => {
        Vue.http.patch(config.root + '/panels/' + opts.panelId, data).then((result) => {
          store.commit("panel.update", result.data);
          resolve(result);
        }, err => reject(err));
      });
    },
    "panel.fetchBlock": function (ctx, opts) {
      console.log('fetch block', opts);
      return new Promise((resolve, reject) => {
        const panel = find(ctx.state.items, item => item.id === opts.panelId);
        const session = find(store.state.sessions.items, item => item.id === panel.sessionId);
        let blockIndex = opts.rotation !== undefined ? opts.rotation : panel.activeRotation;
        blockIndex = store.getters.panelRotationBlockIndex(session, panel, blockIndex);

        const data = {
          index: blockIndex,
        };
        if (panel.activeSet) {
          data.set = panel.activeSet;
        }

        Vue.http.get(config.root + '/panels/' + opts.panelId + '/block', {params: data}).then((result) => {
          store.dispatch('block.set', result.data.block);
          const query = { blockId: result.data.block.id, panelId: opts.panelId};
          Promise.all([
            store.dispatch('exercises.getBlock', query),
            store.dispatch('blockParticipations.getBlock', query),
          ]).then(results => { resolve(results) });
        }, err => reject(err));
      });
    },
    "panel.setStatus": function (ctx, opts) {
      const params = {
        newStatus: opts.newStatus,
        pass: opts.pass,
        cancelled: !!opts.cancelled,
      };
      if (opts.exerciseId) {
        params.exerciseId = opts.exerciseId;
      }
      return Vue.http.post(config.root + '/panels/' + opts.panelId + '/updatestatus', params);
    },
  };

  const getters = {
    panelRotationBlockIndex: () => (session, panel, blockIndex) => {
      console.log('panel block index', panel.index, blockIndex)
      if (sessionLib.getSessionRotationType(session.id) === 'rotation') {
        let rotations = sessionLib.getSetRotations(session, panel.set)

        const exerciseTypes = store.getters.sessionExerciseTypes(session, true)
        let panelIndex = panel.index
        const restRotations = rotations - exerciseTypes.length

        if (restRotations) {
          panelIndex = panel.index + floor(panel.index * restRotations / exerciseTypes.length)
        }
        blockIndex = (rotations + panelIndex - blockIndex) % rotations
        console.log(panelIndex, blockIndex)
      }

      return blockIndex
    },
  };

  const mutations = {
    "panels.setContext": function(state, context) {
      Vue.set(state, 'context', context);
    },
    "panels.setItems": function(state, items) {
      Vue.set(state, 'items', items);
    },
    "panels.setConfigurations": function(state, items) {
      Vue.set(state, 'configurations', items);
    },
    "panel.remove": function(state, id) {
      const panels = filter(state.items, function(item) {
        return item.id !== id;
      });
      Vue.set(state, 'items', panels);
    },
    "panel.update": function(state, updatedItem) {
      const panels = filter(state.items, function(item) {
        return item.id !== updatedItem.id;
      });
      panels.push(updatedItem);
      Vue.set(state, 'items', panels);
    },
    "panel.updateConfiguration": function(state, configuration) {
      const configurations = filter(state.configurations, function(item) {
        return item.panelId !== configuration.panelId;
      });
      configurations.push(configuration);
      Vue.set(state, 'configurations', configurations);
    }
  };

  function listener() {
    socket.on('connect', function () {
      if (state.context) {
        switch (state.context.type) {
          case 'session':
            // store.dispatch('panels.getBySession', {sessionId: state.context.id});
            break;
          case 'event':
            store.dispatch('panels.getByEvent', {eventId: state.context.id});
            break;
        }
      }
    });
    socket.on('panel.update', function(result) {
      if (result.type === 'update') {
        store.commit('panel.update', result.data);
      }
      if (result.type === 'create') {
        Vue.http.get(config.root + '/panels/' + result.data.id + '/configuration').then(resultConfig => {
          store.commit('panel.updateConfiguration', resultConfig.data);
          store.commit('panel.update', result.data);
        });
      }
    });
    socket.on('panel.delete', result => {
      store.commit('panel.remove', result.data);
    });
    socket.emit('join', 'panel.delete');
  }

  store.registerModule('panels', {
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters,
  });

  listener();
}
