<template>
  <div class="c-input">
    <label class="c-input__label" :for="inputName">{{$t('customField.' + config.label)}}</label>
    <multi-select :value="value" :options="config.values" ref="input" class="c-input--multiselect"
                  :multiple="true" :searchable="false" label="name" track-by="id"
                  :taggable="true" :hideSelected="true" :placeholder="$t('select.allExercises')" :closeOnSelect="false"
                  :selectLabel="$t('select.selectOnEnter')"
                  @select="selectValue($event)"
                  @remove="removeValue($event)"
    />
  </div>
</template>

<script>
import base from './customFieldBase.vue'
import clone from "lodash/clone";
import filter from "lodash/filter";
import vueMultiSelect from "vue-multiselect";

export default {
  name: "customFieldExerciseTypes",
  extends: base,
  components: {
    'multi-select': vueMultiSelect,
  },
  methods: {
    selectValue: function(event) {
      const value = clone(this.value);
      value.push(event);
      this.updateInput(value);
    },
    removeValue: function(event) {
      const value = filter(this.value, v => v.id !== event.id);
      this.updateInput(value);
    },
    updateInput: function(value) {
      this.$emit('input', value);
    }
  },
}
</script>

<style scoped>

</style>
