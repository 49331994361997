import Vue from 'vue';
import config from 'client/config';
import socket from 'client/socket';

const filter = require("lodash/filter");

export default function(store) {
  let state = {
    items: [],
    eventId: undefined
  };

  const actions = {
    "groups.get": function(ctx, opts) {
      if (! opts.eventId) {
        return null;
      }

      if (ctx.state.eventId) {
        socket.emit('leave', 'group:' + ctx.state.eventId);
      }

      return new Promise((resolve, reject) => {
        Vue.http.get(config.root + '/events/' + opts.eventId + '/groups').then(result => {
          store.commit('groups.setItems', result.data);
          store.commit('groups.setEventId', opts.eventId);
          socket.emit('join', 'group:' + opts.eventId);
          resolve();
        }, err => reject(err));
      });
    },
    "group.save": function(ctx, opts) {
      return new Promise((resolve, reject) => {
        Vue.http.patch(config.root + '/groups/' + opts.data.id, opts.data).then(result => {
          store.commit('group.update', result.data);
          resolve(result.data);
        }, err => reject(err));
      });
    },
    "group.add": function(ctx, opts) {
      opts.data.eventId = ctx.state.eventId;
      return new Promise((resolve, reject) => {
        Vue.http.post(config.root + '/events/' + ctx.state.eventId + '/groups', opts.data).then(result => {
          store.commit('group.update', result.data);
          resolve(result.data);
        }, err => reject(err));
      });
    },
    "group.remove": function(state, opts) {
      store.commit('group.remove', opts.groupId);
      return new Promise((resolve, reject) => {
        Vue.http.delete(config.root + '/groups/' + opts.groupId).then(() => {
          resolve();
        }, err => reject(err));
      });
    }
  };

  const mutations = {
    "groups.setEventId": function(state, eventId) {
      Vue.set(state, 'eventId', eventId);
    },
    "groups.setItems": function(state, items) {
      Vue.set(state, 'items', items)
    },
    "group.remove": function(state, id) {
      state.items = filter(state.items, function(item) {
        return item.id !== id;
      })
    },
    "group.update": function(state, group) {
      const index = state.items.findIndex(i => i.id === group.id);
      const groups = state.items;
      if (index >= 0) {
        groups.splice(index, 1, group);
      }
      else {
        groups.push(group);
      }
      Vue.set(state, 'items', groups);
    }
  };

  function listener() {
    /*socket.on('connect', function () {
      if (state.eventId) {
        store.dispatch('groups.get', {eventId: state.eventId});
      }
    });*/
    socket.on('group.update', function(result) {
      store.commit('group.update', result.data);
    });
    socket.on('group.delete', result => {
      store.commit('group.remove', result.data);
    });
    socket.emit('join', 'group.delete');
  }

  store.registerModule('groups', {
    state: state,
    mutations: mutations,
    actions: actions
  });

  listener();
}
