<template>
  <div class="c-input c-input--radio">
    <input :value="option" :checked="checked" @change="emitInput($event)" ref="input"
           :id="inputName" :name="inputName" type="checkbox" class="c-input__field c-input__field-checkbox"
           :disabled="disabled"/>
    <label :for="inputName" class="c-input__label c-input__label-checkbox">
      {{getLabel()}}
    </label>
  </div>
</template>

<script>
  import base from './sceInputBase.vue';

  import clone from 'lodash/clone';
  import filter from 'lodash/filter';
  import includes from 'lodash/includes';

  export default {
    name: "sce-checkbox",
    extends: base,
    props: {'option': {type: String, required: false}},
    computed: {
      checked: function() {
        if (this.option) {
          return includes(this.value, this.option);
        }
        return this.value
      },
    },
    methods: {
      emitInput: function(e) {
        let value = null;
        if (this.option) {
          value = clone(this.values);
          if (e.target.checked) {
            value.push(this.option);
          } else {
            value = filter(value, i => i !== this.option);
          }
        } else {
          value = e.target.checked;
        }
        this.$emit('input', value);
      }
    }
  }
</script>
