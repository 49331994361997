<template>
  <sceSelect :value="value" @input="updateInput($event)" ref="field"
             :name="name" :label="'customField.' + config.label"
             :options="options" :config="{emptyLabel: 'none'}" />
</template>

<script>
import base from './customFieldBase.vue'

export default {
  name: "customFieldSelect",
  extends: base,
  computed: {
    options: function() {
      return this.config.values.map(item => ({
        id: item.value,
        name: 'customField.' + item.label,
      }))
    },
  },
}
</script>

<style scoped>

</style>
