'use strict';

import store from 'client/store';

import find from "lodash/find";
import includes from "lodash/includes";
import intersection from "lodash/intersection";
import isEmpty from "lodash/isEmpty";

export default {
  getEventUser: function (event) {
    let user = store.state.user.instance;
    if (user.id !== event.owner_id) {
      user = find(store.state.users.items, item => item.id === event.owner_id);
    }
    return user;
  },

  checkRoles: function(roles) {
    const userRoles = store.state.user.roles;
    const match = intersection(roles, userRoles);
    return ! isEmpty(match);
  },

  checkRole: function(role) {
    return this.checkRoles([role]);
  },

  getTags: function(object) {
    if (object.tags && object.tags.length) {
      return object.tags;
    }
    return ['all'];
  },

  matchTags: function(object1, object2 = null) {
    if (! object2) {
      object2 = store.state.user.instance;
    }

    const object1Tags = this.getTags(object1);
    const object2Tags = this.getTags(object2);

    const match = intersection(object1Tags, object2Tags);

    return ! isEmpty(match);
  },

  matchEventTags: function(object) {
    const event = store.state.events.current
    const user = this.getEventUser(event);
    return this.matchTags(object, user);
  },

  selectImage: function(tag, useDefault = true) {
    const images = store.state.userImages.items;

    const image = find(images, item => includes(item.tags, tag));
    if (! image && useDefault) {
      return find(images, item => includes(item.tags, 'default'));
    }

    return image;
  }
}
