import Vue from 'vue';
import config from 'client/config';

export default function(store) {
  const state = {
    eventDisciplineId: undefined,
    discipline: undefined,
    panelTypes: [],
    formats: [],
    tieBreaks: [],
    exerciseTypes: [],
  }

  const actions = {
    "eventDiscipline.get": function(ctx, opts) {
      return new Promise((resolve, reject) => {
        Vue.http.get(config.root + '/eventDisciplines/' + opts.eventDisciplineId + '/get-info').then(result => {
          store.commit('eventDiscipline.setId', opts.eventDisciplineId)
          store.commit('eventDiscipline.setData', result.data)
          resolve(result)
        }, err => reject(err))
      })
    },
  }

  const mutations = {
    "eventDiscipline.setId": function(state, id) {
      Vue.set(state, 'eventDisciplineId', id);
    },
    "eventDiscipline.setData": function(state, data) {
      Vue.set(state, 'discipline', data.discipline);
      Vue.set(state, 'panelTypes', data.panelTypes);
      Vue.set(state, 'formats', data.formats);
      Vue.set(state, 'tieBreaks', data.tieBreaks);
      Vue.set(state, 'exerciseTypes', data.exerciseTypes);
    },
  }

  store.registerModule('eventDiscipline', {
    state: state,
    mutations: mutations,
    actions: actions,
  })
}
