import Vue from 'vue';
import config from 'client/config';
import socket from 'client/socket';

const concat = require("lodash/concat");
const filter = require("lodash/filter");
const forEach = require("lodash/forEach");
const includes = require("lodash/includes");
const sortBy = require("lodash/sortBy");
const sortedUniqBy = require("lodash/sortedUniqBy");
const uniq = require("lodash/uniq")

import categoryLib from '@/lib/category'

export default function(store) {
  const state = {
    items: [],
    context: undefined
  };

  const actions = {
    "sessions.getByDiscipline": function(ctx, opts) {
      if (ctx.state.context) {
        socket.emit('leave', 'session:' + ctx.state.context.id);
      }
      return new Promise((resolve, reject) => {
        Vue.http.get(config.root + '/eventDisciplines/' + opts.eventDisciplineId + '/sessions').then((result) => {
          store.commit('sessions.setItems', result.data);

          // register for session update events
          const context = {
            id: opts.eventDisciplineId,
            eventDisciplineId: opts.eventDisciplineId
          };

          store.commit('sessions.setContext', context);
          socket.emit('join', 'session:' + opts.eventDisciplineId);
          resolve(result);
        }, err => reject(err));
      });
    },
    "sessions.getByEvent": function(ctx, opts) {
      if (ctx.state.context) {
        socket.emit('leave', 'session:' + ctx.state.context.id);
      }

      return new Promise((resolve, reject) => {
        Vue.http.get(config.root + '/events/' + opts.eventId + '/sessions').then((result) => {
          store.commit('sessions.setItems', result.data);

          // register for session update events
          const context = {
            id: opts.eventId,
            eventId: opts.eventId
          };
          store.commit('sessions.setContext', context);
          socket.emit('join', 'session:' + opts.eventId);
          resolve(result);
        }, err => reject(err));
      });
    },
    "session.save": function(ctx, session) {
      return new Promise((resolve, reject) => {
        Vue.http.patch(config.root + '/sessions/' + session.id, session).then(result => {
          store.commit('session.update', result.data);
          resolve(result.data);
        }, err => reject(err));
      });
    },
    "session.setStatus": function(ctx, opts) {
      return Vue.http.patch(config.root + '/sessions/' + opts.sessionId, {status: opts.newStatus});
    },
    "session.setRotationStatus": function(ctx, opts) {
      return Vue.http.post(config.root + '/sessions/' + opts.sessionId + '/setRotationStatus', {
        index: opts.index, status: opts.newStatus
      });
    },
    "session.add": function(ctx, session) {
      if (session.index === undefined) {
        session.index = ctx.state.items.length;
      }
      return new Promise((resolve, reject) => {
        Vue.http.post(config.root + '/eventDisciplines/' + session.eventDisciplineId + '/sessions', session).then(
          result => {
            store.commit('session.update', result.data);
            resolve(result);
          },
          error => { reject(error) }
        );
      });
    },
    "session.remove": function(state, session) {
      store.commit('session.remove', session.id);
      return new Promise((resolve, reject) => {
        Vue.http.delete(config.root + '/sessions/' + session.id).then(result => {
          store.commit('session.remove', session.id);
          resolve(result);
        }, err => reject(err));
      });
    }
  };

  const getters = {
    sessionExerciseTypes: () => (session, filterByIndex = false) => {
      let exerciseTypeIds = []
      forEach(session.categories, item => {
        const catExerciseTypeIds = categoryLib.getCategoryExerciseTypes(item.categoryId, session.roundIndex)
        exerciseTypeIds = concat(exerciseTypeIds, catExerciseTypeIds)
      });
      exerciseTypeIds = uniq(exerciseTypeIds)

      if (session.exerciseTypes && session.exerciseTypes.length) {
        exerciseTypeIds = filter(exerciseTypeIds, item => includes(session.exerciseTypes, item));
      }

      let result = sortBy(filter(store.state.eventDiscipline.exerciseTypes,
        item => includes(exerciseTypeIds, item.id)), 'index');

      if (filterByIndex) {
        result = sortedUniqBy(result, item => item.rotationIndex !== undefined ? item.rotationIndex : item.index);
      }

      return result;
    },
  };

  const mutations = {
    "sessions.setContext": function(state, context) {
      Vue.set(state, "context", context);
    },
    "sessions.setItems": function(state, items) {
      Vue.set(state, 'items', items)
    },
    "session.remove": function(state, id) {
      state.items = filter(state.items, function(item) {
        return item.id !== id;
      })
    },
    "session.update": function(state, session) {
      const index = state.items.findIndex(i => i.id === session.id);
      const sessions = state.items;
      if (index >= 0) {
        sessions.splice(index, 1, session);
      }
      else {
        sessions.push(session);
      }
      Vue.set(state, 'items', sessions);
    }
  };

  function listener() {
    socket.on('connect', function () {
      if (state.context) {
        if (state.context.eventDisciplineId) {
          store.dispatch('sessions.getByDiscipline', { eventDisciplineId: state.context.id });
        }
        if (state.context.eventId) {
          store.dispatch('sessions.getByEvent', { eventId: state.context.id });
        }
      }
    });
    socket.on('session.update', function(result) {
      if (result.type === 'update') {
        store.commit('session.update', result.data);
      }
      if (result.type === 'create') {
        store.commit('session.update', result.data);
      }
    });
    socket.on('session.delete', result => {
      store.commit('session.remove', result.data);
    });
    socket.emit('join', 'session.delete');
  }

  store.registerModule('sessions', {
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters,
  });

  listener();
}
