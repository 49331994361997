<template>
  <router-link tag="a" class="c-button" :class="{'c-button--icon': icon}" exact :to="route">
    <svg v-if="icon" role="img" class="c-button__icon">
      <use :href="'images/' + icon"></use>
    </svg>
    <span v-if="label" class="c-button__label" >{{$t(label)}}</span>
    <span v-if="extraLabel" class="c-button__extra-label">{{ $t(extraLabel) }}</span>
  </router-link>
</template>

<script>
  export default {
    name: "sceRouteButton",
    props: ['label', 'extraLabel', 'icon', 'route'],
  }
</script>
