<template>
  <div class="c-input">
    <label class="c-input__label" :for="inputName">{{getLabel()}}</label>
    <textarea ref="input" :value="value" @input="updateInput()" class="c-input__field c-input__field-textarea" type="text"
              :rows="rows" :id="inputName" :name="inputName" :disabled="disabled" ></textarea>
  </div>
</template>

<script>
  import base from './sceInputBase.vue';

  export default {
    name: "sce-text-area",
    extends: base,
    props: ['rows'],
  }
</script>
