import Vue from 'vue';

function navigation(router) {
  return new Vue({
    router,
    methods: {
      goto(route) {
        const currentPath = this.$route?.fullPath
        const newPath = this.$router?.resolve(route).route.fullPath
        if (newPath && newPath === currentPath) return
        this.$router.push(route)
      }
    }
  })
}

export default {
  install(Vue, router) {
    Vue.prototype.$navigation = navigation(router)
  }
};
