import Vue from 'vue';
import config from 'client/config';

export default function(store) {
  let state = {
    items: []
  };

  let actions = {
    "disciplines.get": function() {
      return new Promise((resolve, reject) => {
        Vue.http.get(config.root + '/disciplines').then(result => {
          store.commit('disciplines.setItems', result.data);
          resolve(result.data);
        }, err => reject(err));
      });
    }
  };

  let mutations = {
    "disciplines.setItems": function(state, items) {
      Vue.set(state, 'items', items);
    }
  };


  store.registerModule('disciplines', {
    state: state,
    mutations: mutations,
    actions: actions
  });
}
