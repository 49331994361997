import Vue from 'vue';
import config from 'client/config';
import socket from 'client/socket';

const filter = require("lodash/filter");

export default function(store) {
  const state = {
    items: [],
    eventId: undefined
  };

  const actions = {
    "members.get": function(ctx, opts) {
      if (! opts.eventId) {
        return null;
      }

      if (ctx.state.eventId) {
        socket.emit('leave', 'clubMember:' + ctx.state.eventId);
      }

      return new Promise((resolve, reject) => {
        Vue.http.get(config.root + '/events/' + opts.eventId + '/members').then(result => {
          store.commit('members.setItems', result.data);
          store.commit('members.setEventId', opts.eventId);
          socket.emit('join', 'clubMember:' + opts.eventId);
          resolve(result);
        }, err => reject(err));
      });
    },
    "member.save": function(ctx, opts) {
      return new Promise((resolve, reject) => {
        Vue.http.patch(config.root + '/clubMembers/' + opts.memberId, opts.data).then(result => {
          store.commit('member.update', result.data);
          resolve(result.data);
        }, err => reject(err));
      });
    },
    "member.add": function(ctx, opts) {
      opts.data.eventId = ctx.state.eventId;
      return new Promise((resolve, reject) => {
        Vue.http.post(config.root + '/events/' + ctx.state.eventId + '/members', opts.data).then(result => {
          store.commit('member.update', result.data);
          resolve(result.data);
        }, err => reject(err));
      });
    },
    "member.remove": function(state, opts) {
      store.commit('member.remove', opts.memberId);
      return new Promise((resolve, reject) => {
        Vue.http.delete(config.root + '/clubMembers/' + opts.memberId).then(() => {
          resolve();
        }, err => reject(err));
      });
    }
  };

  const mutations = {
    "members.setEventId": function(state, eventId) {
      Vue.set(state, 'eventId', eventId);
    },
    "members.setItems": function(state, items) {
      Vue.set(state, 'items', items)
    },
    "member.remove": function(state, id) {
      state.items = filter(state.items, function(item) {
        return item.id !== id;
      })
    },
    "member.update": function(state, member) {
      var index = state.items.findIndex(i => i.id === member.id);
      var members = state.items;
      if (index >= 0) {
        members.splice(index, 1, member);
      }
      else {
        members.push(member);
      }

      Vue.set(state, 'items', members);
    }
  };

  function listener() {
    /*socket.on('connect', function () {
      if (state.eventId) {
        store.dispatch('members.get', {eventId: state.eventId});
      }
    });*/
    socket.on('clubMember.update', function(result) {
      store.commit('member.update', result.data);
    });
    socket.on('clubMember.delete', result => {
      store.commit('member.remove', result.data);
    });
    socket.emit('join', 'clubMember.delete');
  }

  store.registerModule('members', {
    state: state,
    mutations: mutations,
    actions: actions
  });

  listener();
}
