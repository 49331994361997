import Vue from 'vue';
import config from 'client/config';

export default function (store) {
  let state = {
    token: undefined,
    user: undefined,
    event: undefined,
    events: [],
  };

  const actions = {
    "importer.login": function (ctx, creds) {
      const remoteUrl = store.state.environment.remoteApp + '/seUsers/login';
      return new Promise((resolve, reject) => {
        Vue.http.post(remoteUrl, creds).then(result => {
          ctx.commit("importer.token.set", result.data);
          resolve(result.data);
        }, err => reject(err));
      });
    },
    "importer.user.set": function (ctx) {
      const remoteUrl = store.state.environment.remoteApp + '/seUsers/'  + ctx.state.token.userId + '/profile';
      return new Promise((resolve, reject) => {
        Vue.http.get(remoteUrl, {headers: {Authorization: ctx.state.token.id}}).then(result => {
          store.commit('importer.user.set', result.data.user);
          resolve(result.data.user);
        }, err => reject(err));
      });
    },
    "importer.events.get": function (ctx) {
      const userId = store.state.user.instance.id;
      const remoteUrl = store.state.environment.remoteApp + '/events/list';
      return Vue.http.get(remoteUrl, {params: {owner_id: userId}}).then(function (result) {
        ctx.commit("importer.events.set", result.data);
      });
    },
    "importer.events.getAdmin": function (ctx) {
      const remoteUrl = store.state.environment.remoteApp + '/events/list';
      return Vue.http.get(remoteUrl).then(function (result) {
        ctx.commit("importer.events.set", result.data);
      });
    },
    "importer.event.get": function (ctx, eventId) {
      const userId = store.state.user.instance.id;
      const remoteUrl = store.state.environment.remoteApp + '/seUsers/' + userId + '/events/' + eventId

      return new Promise((resolve, reject) => {
        Vue.http.get(remoteUrl).then(function (result) {
          ctx.commit("importer.event.set", result.data)
          resolve(result.data)
        }, err => reject(err))
      })
    },
    "importer.user.start": function () {
      return Vue.http.post(config.root + '/importUser/' + state.token.userId);
    },
    'importer.user.check': function() {
      return Vue.http.post(config.root + '/importUserCheck/' + state.token.userId);
    },
    "importer.event.start": function (ctx, eventId) {
      return Vue.http.post(config.root + '/importEvent/' + eventId);
    },
    'importer.event.check': function(ctx, eventId) {
      return Vue.http.post(config.root + '/importEventCheck/' + eventId);
    },
  };

  const mutations = {
    "importer.token.set": function (state, token) {
      Vue.set(state, 'token', token);
    },
    "importer.user.set": function (state, user) {
      Vue.set(state, 'user', user);
    },
    "importer.events.set": function (state, events) {
      Vue.set(state, 'events', events)
    },
    "importer.event.set": function (state, event) {
      Vue.set(state, 'event', event)
    }
  };

  store.registerModule('importer', {
    state: state,
    mutations: mutations,
    actions: actions
  })
}
